import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import Settings from 'utils/Settings';
import { get, getImageUrl } from 'utils/magnolia';
import url from 'utils/url';
import { GetCategories } from 'utils/commerce';
import { ReactComponent as Search } from 'img/search.svg';
import NodeLink from 'components/Link';

import { ReactComponent as ArrowDark } from 'img/arrow-dark.svg';
import { ReactComponent as ArrowWhite } from 'img/arrow-white.svg';

const CategoryNav = ({ category }) => {
  const [enhancedCategories, setEnhancedCategories] = useState({});
  const [activeCategory, setActiveCategory] = useState(null);

  const enhanceCategory = (categoryId) => {
    const getEnhancedCategory = async () => {
      const enhancementInfo = await get('/.rest/ecommerce-categories?externalId="' + categoryId + '"');
      const newEnhancedCategories = { ...enhancedCategories, ...{ [categoryId]: enhancementInfo?.results?.[0] } };
      setEnhancedCategories(newEnhancedCategories);
    };

    if (!enhancedCategories[categoryId]) getEnhancedCategory();
    setActiveCategory(categoryId);
  };

  let enhancement = null;
  if (enhancedCategories[activeCategory]?.image) {
    enhancement = enhancedCategories[activeCategory];
  } else if (enhancedCategories[category.id]?.image) {
    enhancement = enhancedCategories[category.id];
  }

  let bannerClass = 'Header__banner';
  let isVideo = false;
  const style = {};

  if (enhancement?.textColor) style.color = enhancement.textColor;
  if (enhancement?.image) {
    bannerClass += ' Header__bannerImage';
    if (enhancement.image.metadata?.format?.indexOf('video') > -1) {
      isVideo = true;
    } else {
      style.backgroundImage = `url(${getImageUrl(enhancement?.image)})`;
    }
  }

  return (
    <li className='Header__categories'>
      <span onMouseEnter={() => enhanceCategory(category.id)}>
        <NodeLink key={category.id} node={category} isCategory={true} />
      </span>
      {(category.children.length > 0 || enhancement?.image) && (
        <nav className='Header__subnavWrapper'>
          <div className='Header__subnavInner wrapper'>
            <ul className='Header__subnav no-list-style'>
              {category.children.map((subCategory) => (
                <li key={subCategory.id} onMouseEnter={() => enhanceCategory(subCategory.id)}>
                  <NodeLink key={subCategory.id} node={subCategory} isCategory={true} />
                </li>
              ))}
            </ul>
            {enhancement?.image && (
              <div className={bannerClass} style={style}>
                {isVideo && <video src={getImageUrl(enhancement.image)} muted autoPlay loop />}
                <div className='Header__bannerInner'>
                  {enhancement?.title && <div className='Header__bannerTitle'>{enhancement.title}</div>}
                  {enhancement?.cta?.text && (
                    <Link className='Btn' to={url.getPageLink(enhancement.cta?.link) || '#'}>
                      {enhancement.cta?.text}
                      <ArrowDark className='Btn__arrow Btn__arrow--dark' />
                      <ArrowWhite className='Btn__arrow Btn__arrow--white' />
                    </Link>
                  )}
                </div>
              </div>
            )}
          </div>
        </nav>
      )}
    </li>
  );
};

const MobileMenuItem = ({ node, setShowMobileMenu, isCategory }) => {
  const [showSubmenu, setShowSubmenu] = useState(false);

  return (
    <li className='Header__mobileNav'>
      <NodeLink
        key={node['@id']}
        node={node}
        onClickFn={() => setShowMobileMenu(false)}
        isCategory={isCategory || false}
      />
      {node.children?.length > 0 && (
        <button onClick={() => setShowSubmenu((showSubmenu) => !showSubmenu)}>
          {showSubmenu ? (
            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
              <path d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' />
            </svg>
          ) : (
            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
              <path d='M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z' />
            </svg>
          )}
        </button>
      )}
      {node.children?.length > 0 && showSubmenu && (
        <ul className='no-list-style'>
          {node.children.map((subNode) => (
            <li key={subNode['@id'] || subNode.id} className='Header__mobileSubnav'>
              <NodeLink
                key={subNode['@id']}
                node={subNode}
                onClickFn={() => setShowMobileMenu(false)}
                isCategory={isCategory || false}
              />
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

function Header(props) {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [categories, setCategories] = useState([]);
  const [rightNav, setRightNav] = useState([]);
  const { pathname } = useLocation();
  const [searchInput, setSearchInput] = useState(url.getSearchParam('query') || '');
  const history = useHistory();
  const { navigation } = props;

  useEffect(() => {
    let leftNav = [];
    let rightNav = [];

    navigation['@nodes'].forEach((nodeName) => {
      const node = navigation[nodeName];

      if (node.linkType?.field === 'category') {
        leftNav.push(node);
      } else {
        rightNav.push(node);
      }
    });

    setRightNav(rightNav);

    const getCategories = async () => {
      const allCategories = await GetCategories();

      let categories = leftNav.map((cat) => {
        const filteredCategory = allCategories.filter((category) => category.key === cat.linkType.category);
        return filteredCategory.length > 0 ? { ...cat, ...filteredCategory[0] } : cat;
      });

      categories = categories.filter((category) => category.key);

      setCategories(categories);
    };

    getCategories();
  }, [navigation]);

  useEffect(() => {
    setSearchInput(url.getSearchParam('query') || '');
  }, [pathname]);

  function renderSearchInput() {
    return (
      <form
        className='Header__search'
        onSubmit={(e) => {
          history.push('/search?query=' + searchInput);
          setShowMobileMenu(false);
          e.preventDefault();
        }}
      >
        <input
          type='text'
          placeholder={Settings.getItem('translations').search}
          value={searchInput}
          onChange={(e) => setSearchInput(e.currentTarget.value)}
        />
        <button>
          <Search />
        </button>
      </form>
    );
  }

  return (
    <div className='Header-wrapper'>
      <header className='Header wrapper'>
        <nav className='display-only float-left'>
          <ul className='no-list-style'>
            {categories.map((category) => (
              <CategoryNav key={category.id} category={category} />
            ))}
          </ul>
        </nav>
        <Link
          className='Header__logo contain'
          to='/'
          style={{ backgroundImage: 'url(' + getImageUrl(Settings.getItem('logo')) + ')' }}
        />
        <nav className='display-only float-right Header__rightNav'>
          {rightNav.map((node) => (
            <NodeLink key={node['@id']} node={node} />
          ))}
          <span>{Settings.getItem('translations').cart}</span>
          {renderSearchInput()}
        </nav>
        <nav className='mobile-only'>
          <span onClick={() => setShowMobileMenu((prev) => !prev)}>MENU</span>
          {showMobileMenu && (
            <div className='Header__mobileNavWrapper'>
              <ul className='no-list-style'>
                {categories.map((node) => (
                  <MobileMenuItem
                    key={node['@id']}
                    node={node}
                    setShowMobileMenu={setShowMobileMenu}
                    isCategory={true}
                  />
                ))}
                {rightNav.map((node) => (
                  <MobileMenuItem key={node['@id']} node={node} setShowMobileMenu={setShowMobileMenu} />
                ))}
              </ul>
              <span>{Settings.getItem('translations').cart}</span>
              {renderSearchInput()}
            </div>
          )}
        </nav>
      </header>
    </div>
  );
}

export default Header;
